@import '../../../../scss/theme-bootstrap';

.site-banner {
  width: 100%;
  background: $color-black;
  text-align: center;
  color: $color-white;
  border-bottom: 1px solid $color-white;
  font-size: 12px;
  text-transform: uppercase;
  padding: 10px 0;
  @include breakpoint($landscape-up) {
    font-size: 13px;
    padding: 14px 0;
  }
  .basic-grid {
    padding-#{$rdirection}: 0;
  }
  &__copy {
    max-width: 830px; // Arbitrary value
    margin: auto;
    position: relative;
    @include swap_direction(padding, 0 30px 0 20px);
    @include breakpoint(420px) {
      @include swap_direction(padding, 0 40px 0 30px);
    }
    @include breakpoint($landscape-up) {
      @include swap_direction(padding, 0 50px 0 20px);
    }
  }
  p {
    margin-bottom: 0;
  }

  // The editor entered link
  &__copy a {
    color: $color-white;
  }
  &__close {
    position: absolute;
    top: 1px;
    #{$rdirection}: 15px;
    color: $color-white;
    display: block;
    cursor: pointer;
    font-size: 13px;
    .icon--close {
      font-weight: bold;
    }
    @include breakpoint($landscape-up) {
      #{$rdirection}: 20px;
      top: -1px;
    }
    &.custom-button {
      &::hover {
        color: $color-white;
      }
    }
  }
  &__offer {
    display: none;
    margin: 10px 0 -10px 0;
    background: $color-white;
    color: $color-black;
    position: absolute; // Trick to get fixed element relative to container
    &.active {
      display: block;
      &:before {
        @include triangle(30px, $color-black, down);
        @include transform(translateX(-50%));
        content: ' ';
        #{$ldirection}: 50%;
        position: fixed;
        z-index: 21;
      }
    }
    &-close {
      position: absolute;
      #{$rdirection}: 15px;
      top: 13px;
      text-decoration: none;
      &:hover,
      &:active {
        text-decoration: none;
      }
      @include breakpoint($landscape-up) {
        top: 12px;
        #{$rdirection}: 20px;
      }
      .icon {
        font-size: 24px;
      }
    }
    &-content {
      width: 100%;
      max-width: 830px; // Arbitrary value
      background: $color-white;
      position: fixed; // Used inside of a parent with position:absolute to get fixed positioning relative to element
      #{$ldirection}: 50%;
      transform: translateX(-50%);
      padding-top: 25px;
      padding-bottom: 65px;
      margin-top: 1px;
      z-index: 20; // Don't be below the logo
      height: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      @include breakpoint($portrait-up) {
        padding-top: 28px;
        padding-bottom: 35px;
        height: auto;
        overflow: auto;
        padding-bottom: 35px;
      }
      @include breakpoint($landscape-up) {
        margin-top: 4px;
      }
      .basic-grid {
        &__item {
          @include breakpoint($portrait-up) {
            padding-bottom: 5px;
          }
        }
        &__header {
          font-size: 32px;
          @include breakpoint($portrait-up) {
            font-size: 36px;
            margin-bottom: 20px;
          }
        }
        &__subheader {
          margin-bottom: 5px;
          @include breakpoint($portrait-up) {
            margin-bottom: 20px;
          }
        }
      }
    }

    // Offer touts have a border styling on top
    // Last one should have one on the bottom here
    .basic-grid__item:last-child {
      border-bottom: $border;
    }
  }
  &__more {
    color: $color-black;
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
    @include breakpoint($portrait-up) {
      margin-top: 30px;
      margin-bottom: 0;
    }
  }
  &__link {
    @include bordered-link();
  }
}
